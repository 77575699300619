import React from "react"
import { FieldArray } from "react-final-form-arrays"
import Employment from "./Employment"
import RowWarning from "../../blocks/RowWarning"

import { ReactComponent as Plus } from "../../../assets/images/plus.svg"

const addHandler = fields => {
  fields.push({ degree: "", college: "" })
}

const validateArray = values => {
  if (!values.length) return

  const errorsArray = []

  values.forEach(value => {
    if (value) {
      const errors = {}

      if (!value.from) {
        errors.from = "Required"
      }

      if (!value.to && !value.is_current_employment) {
        errors.to = "Required"
      }

      if (!value.school) {
        errors.school = "Required"
      }

      if (!value.position) {
        errors.position = "Required"
      }

      errorsArray.push(errors)
    }
  })

  if (errorsArray.some(object => !_.isEmpty(object))) {
    return errorsArray
  } else {
    return undefined
  }
}

const EmploymentHistory = ({ dataSources }) => {
  // <div className="col-lg-18">
  return (
    <FieldArray
      name="employment_histories"
      initialValue={dataSources.user.profile.employment_histories}
      validate={validateArray}
    >
      {({ fields }) => (
        <div>
          <div className="row mb-4">
            <h4 className="col-sm-18">Employment history</h4>
          </div>
          <div>
            {fields.map((name, index) => (
              <Employment key={index} name={name} index={index} fields={fields} schoolUrl={dataSources.urls.schools} />
            ))}
          </div>
          <hr className="divider" />
          <div className="mt-2 mb-5">
            <RowWarning className="panel_body-warning" name="employmentHistoriesLength">
              <button
                type="button"
                className="btn btn-outline-primary d-flex align-items-center"
                onClick={() => addHandler(fields)}
              >
                <div className="icon-16-px">
                  <Plus />
                </div>
                <span className="ml-3">Add position</span>
              </button>
            </RowWarning>
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export default EmploymentHistory
