export const elText = (el, ids) => {
  if (ids && ids.findIndex(id => el.id === id) > -1) {
    return `[\u2713]`
  } else {
    return `[\u00A0\u00A0\u00A0]`
  }
}

export const toggleId = (fields, id) => {
  const index = fields.value.findIndex(el => el === id)
  if (index > -1) {
    fields.remove(index)
  } else {
    fields.push(id)
  }
}

export const deleteId = (fields, index) => {
  fields.remove(index)
}

export const requiredClass = meta => {
  return meta.error && meta.touched ? "red-border" : ""
}
