import React, { Fragment, useEffect, useState } from "react"
import { DashboardCard } from "../page"
import { Row } from "react-bootstrap"
import apiClient from "../../../../common/apiClient"

const getTodaysDateForApi = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, "0")
  const day = String(today.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

function formatISOToTimeString(isoString) {
  const date = new Date(isoString)
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12
  const minutesStr = minutes < 10 ? "0" + minutes : minutes

  const timeString = `${hours}:${minutesStr} ${ampm}`
  return timeString
}

const TodaysSessionsDashboard = () => {
  const [sessions, setSessions] = useState([])

  useEffect(() => {
    const fetchSessions = async () => {
      const response = await apiClient.get(`/api/v2/school_admins/sessions/${getTodaysDateForApi()}`)
      if (!response || !response.data) {
        setSessions([])
      } else {
        const formatResponseTime = response.data.sessions.map(session => ({
          ...session,
          time: formatISOToTimeString(session.time),
        }))
        setSessions(formatResponseTime)
      }
    }
    fetchSessions()
  }, [])

  return (
    <Fragment>
      <DashboardCard
        title="Today's Sessions"
        style={{
          overflow: "scroll",
          maxHeight: "290px",
        }}
      >
        {!sessions.length ? (
          <p className="text-center m-0">No Scheduled Sessions!</p>
        ) : (
          sessions.map(session => (
            <Row key={session.key} className="mt-3">
              <div className="font-size-medium font-weight-semibold">
                {session.time} - {session.students}
              </div>
            </Row>
          ))
        )}
      </DashboardCard>
    </Fragment>
  )
}

export default TodaysSessionsDashboard
