import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { ResourcesDashboard } from "./resources"
import TodaysSessionsDashboard from "./todays_sessions"
import { InstructionalScheduleDashboard } from "./instructional_schedule"
import { InstructionalDaysDashboard } from "./instructional_days"

export const DashboardCard = ({ title, children, style }) => (
  <Col sm>
    <Card className="card" style={{ alignItems: "center", minHeight: "310px", ...style }}>
      <Card.Body className="p-4 text-center">
        <h4 className="text-black-50">{title}</h4>
        {children}
      </Card.Body>
    </Card>
  </Col>
)

export const SchoolAdminDashboard = () => {
  return (
    <div>
      <Container fluid className="p-0 d-flex flex-column flex-grow-1">
        <Row>
          <Col className="p-0">
            <h2 className="users-navbar_title mb-4 ml-2">Dashboard</h2>
            <Container className="school-admins-dashboard-content">
              <Row className="gap-2">
                <InstructionalDaysDashboard />
                <TodaysSessionsDashboard />
                <ResourcesDashboard />
              </Row>
              <Row>
                <InstructionalScheduleDashboard />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
