import React from "react"
import { Field, FormSpy } from "react-final-form"
import { OnChange } from "react-final-form-listeners"
import { Form, Col, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import cn from "classnames"
import { deleteId, requiredClass } from "../../../helpers/profile_form"
import { formatDate, parseDate } from "../../../helpers/dates"
import SchoolSelect from "./SchoolSelect"
import FormRow from "../../../components/blocks/FormRow"
import RowWarning from "../../blocks/RowWarning"

import { ReactComponent as Delete } from "../../../assets/images/delete.svg"

const formRowProps = {
  LabelProps: { sm: 24, lg: 6, className: "v-2" },
  ColProps: { sm: 24, lg: 18, className: "mb-0" },
  RowProps: {},
}

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {value => {
              if (value === becomes) {
                onChange(to)
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
)

const Employment = ({ name, index, fields, schoolUrl }) => {
  return (
    <div>
      <WhenFieldChanges field={`${name}.is_current_employment`} becomes={true} set={`${name}.to`} to={null} />

      <RowWarning className="panel_body-warning" name={["employment_histories", index, "from"]}>
        <RowWarning className="panel_body-warning" name={["employment_histories", index, "to"]}>
          <FormRow label="Dates" {...formRowProps} required className="mb-4">
            <Row className="mt-n1">
              <Col lg={18}>
                <Row className="align-items-center mb-4">
                  <Col xs={22}>
                    <Row>
                      <Col xs={11}>
                        <Field name={`${name}.from`}>
                          {({ input, meta }) => (
                            <DatePicker
                              placeholderText="From"
                              selected={parseDate(input.value)}
                              onChange={date => input.onChange(formatDate(date))}
                              startDate={parseDate(input.value)}
                              maxDate={parseDate(fields.value[index].to) || new Date()}
                              endDate={parseDate(fields.value[index].to)}
                              dateFormat="MMMM, yyyy"
                              customInput={
                                <input
                                  className={cn("form-control v-2", requiredClass(meta))}
                                  data-test="employment_start"
                                />
                              }
                              showMonthYearPicker
                              selectsStart
                            />
                          )}
                        </Field>
                      </Col>
                      <Col xs={13} className="d-flex align-items-center">
                        <div className="text-center align-self-center px-2 mr-2">&mdash;</div>
                        <Field name={`${name}.to`}>
                          {({ input, meta }) => (
                            <DatePicker
                              placeholderText="To"
                              selected={parseDate(input.value)}
                              onChange={date => input.onChange(formatDate(date))}
                              startDate={parseDate(fields.value[index].from)}
                              endDate={parseDate(input.value)}
                              minDate={parseDate(fields.value[index].from)}
                              maxDate={new Date()}
                              dateFormat="MMMM, yyyy"
                              disabled={fields.value[index].is_current_employment}
                              customInput={
                                <input
                                  className={cn("form-control v-2", requiredClass(meta))}
                                  data-test="employment_end"
                                />
                              }
                              showMonthYearPicker
                              selectsEnd
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={2}>
                    <button
                      type="button"
                      className="icon-button -hover-red"
                      onClick={() => {
                        deleteId(fields, index)
                      }}
                    >
                      <div className="icon-20-px">
                        <Delete />
                      </div>
                    </button>
                  </Col>
                </Row>

                <Row>
                  <Col lg={18}>
                    <Row>
                      <Col xs={22}>
                        <Field
                          name={`${name}.is_current_employment`}
                          initialValue={fields.value[index].is_current_employment}
                          type="checkbox"
                        >
                          {({ input }) => (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`currentEmployment-${index}`}
                                {...input}
                              />
                              <label className="custom-control-label" htmlFor={`currentEmployment-${index}`}>
                                Is my current employment
                              </label>
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormRow>
        </RowWarning>
      </RowWarning>

      <Field name={`${name}.school`}>
        {({ input, meta }) => (
          <RowWarning className="panel_body-warning" name={["employment_histories", index, "school"]}>
            <FormRow label="School" {...formRowProps} required className="mb-4">
              <Row>
                <Col lg={18}>
                  <Row>
                    <Col lg={22}>
                      <SchoolSelect
                        placeholder="Start typing school name"
                        className={cn("form-control v-2", requiredClass(meta))}
                        source={schoolUrl}
                        initValue={input.value}
                        onChange={el => {
                          input.onChange(el)
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormRow>
          </RowWarning>
        )}
      </Field>

      <Field name={`${name}.position`}>
        {({ input, meta }) => (
          <RowWarning className="panel_body-warning" name={["employment_histories", index, "position"]}>
            <FormRow label="Position" {...formRowProps} required className="mb-4">
              <Row>
                <Col lg={18}>
                  <Row>
                    <Col lg={22}>
                      <Form.Control
                        {...input}
                        data-test="employment_position"
                        placeholder="Your position"
                        className={cn("v-2", requiredClass(meta))}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormRow>
          </RowWarning>
        )}
      </Field>

      {index !== fields.length - 1 && <hr />}
    </div>
  )
}

export default Employment
