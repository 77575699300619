import React, { Fragment, useEffect, useRef, useState } from "react"
import { DashboardCard } from "../page"
import dayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/react"
import MobileDetect from "mobile-detect"
import FormSelect from "../../../blocks/FormSelect"
import { getMonths } from "../../../../helpers/dates"
import apiClient from "../../../../common/apiClient"
import { eventNewDiv } from "./eventRender"

const renderDefaultMonth = () => {
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()
  const monthFormatted = currentMonth <= 9 ? `0${currentMonth}` : currentMonth
  return `${currentYear}-${monthFormatted}-01`
}

const formatEvents = calendar => {
  if (!calendar) []
  const schedules = Object.entries(calendar)
    .map(([key, prop]) => {
      if (!prop) return null
      if (prop) {
        return {
          start: key,
          display: "background",
        }
      }
    })
    .filter(i => i)
  return schedules
}

export const InstructionalScheduleDashboard = () => {
  const currentMonth = getMonths()[new Date().getMonth()]
  const [events, setEvents] = useState([])
  const [month, setMonth] = useState(currentMonth.label)
  const calendarRef = useRef(null)

  function handleDateChange(monthValue) {
    const calendarApi = calendarRef.current.getApi()
    const selectedMonth = getMonths().find(m => m.label === monthValue)
    calendarApi.gotoDate(selectedMonth && selectedMonth.date)
    setMonth(monthValue)
  }
  const md = new MobileDetect(window.navigator.userAgent)
  const monthsList = getMonths().map(month => ({ label: month.label, value: month.name }))

  useEffect(() => {
    const getScheduleData = async () => {
      const response = await apiClient.get(`/api/v2/school_admins/calendar/${month}`)
      if (response && response.data.calendar) {
        const schedules = formatEvents(response.data.calendar)
        setEvents(schedules)
      }
    }
    getScheduleData()
  }, [month])

  return (
    <Fragment>
      <DashboardCard title="Instructional Schedule" style={{ marginBottom: "25px" }}>
        <FormSelect
          className="float-right w-25 mb-3"
          options={monthsList}
          defaultValue={currentMonth.label}
          onChange={event => handleDateChange(event.target.value)}
        />

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          header={true}
          allDaySlot={false}
          initialView="dayGridMonth"
          weekends={false}
          events={events}
          aspectRatio={md.mobile() ? 0.5 : 1.35}
          height="auto"
          defaultDate={renderDefaultMonth()}
          eventRender={eventNewDiv}
          themeSystem="bootstrap5"
        />
      </DashboardCard>
    </Fragment>
  )
}
