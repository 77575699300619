import React, { useContext, useMemo } from "react"
import { EditConnectionForm, CreateConnectionForm } from "../forms/edit_connection"
import { Dialog } from "../../blocks/Dialog"
import { className } from "../../../helpers/className"
import { Entities } from "../../../constants"
import useViewport from "../../../hooks/useViewport"
import { ConfirmationModalContext, DataContext, QueryContext } from "../../../contexts"
import { deleteConnection } from "../configurations/domain"

export const TutorEditDialog = ({ connections, open, onClose }) => {
  const { isPhoneViewport, isTabletViewport } = useViewport()
  const isMobile = isPhoneViewport || isTabletViewport
  const { openConfirmationModal } = useContext(ConfirmationModalContext)
  const { getHookState } = useContext(QueryContext)
  const { scheduleId } = useContext(DataContext)
  const { request: remove } = getHookState(deleteConnection)

  const title = useMemo(() => {
    if (connections && connections.length > 0) {
      if (connections.length === 1) {
        const [connection] = connections
        return `Edit ${connection.tutor.first_name} ${connection.tutor.last_name}`
      } else return `Edit ${connections.length} connections`
    } else return "Add tutor"
  }, [connections])

  return (
    <Dialog open={open} onClose={onClose} className="edit_tutor_dialog" title={title}>
      {connections && connections.length > 0 ? (
        <EditConnectionForm
          connections={connections}
          onCancel={onClose}
          onSave={onClose}
          buttonsClassName="btrust-dialog__actions align-items-center"
        >
          {connections.length === 1 && (
            <button
              type="button"
              className={className(
                "btn btn-link btn-link-error text-no-wrap",
                isMobile ? "align-self-auto" : "ml-auto"
              )}
              onClick={() => {
                onClose()
                openConfirmationModal({
                  title: "Delete connection",
                  content: "Are you sure you want to delete this connection?",
                  buttonClassName: "btn btn-error",
                  onConfirm: async () => {
                    await remove({
                      entitiesIds: {
                        [Entities.Schedules]: scheduleId,
                        [Entities.Connections]: connections[0].id,
                      },
                    })
                  },
                })
              }}
            >
              Delete connection
            </button>
          )}
        </EditConnectionForm>
      ) : (
        <CreateConnectionForm
          onCancel={onClose}
          onSave={onClose}
          buttonsClassName="btrust-dialog__actions align-items-center"
        />
      )}
    </Dialog>
  )
}
