import { ArrowLeftIcon, ArrowRightIcon } from "@/assets/images"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { renderToString } from "react-dom/server"
import Alerter from "../../../common/alerter"
import apiClient from "../../../common/apiClient"
import { STUDENTS_DASHBOARD_TEXTS } from "./contants"

const HeaderCell = ({ date }) => (
  <div className="student-dashboard-column-header">
    <div className="student-dashboard-column-header-day">{moment(date).format("DD")}</div>
    <div className="student-dashboard-column-header-text">{moment(date).format("dddd")}</div>
  </div>
)

export const StudentSchedule = ({ id }) => {
  const calendarEl = useRef(null)
  const columnHeaderHtml = useCallback(date => renderToString(<HeaderCell date={date} />), [])
  const [scheduledSessions, setScheduledSessions] = useState([])
  const [weekStart, setWeekStart] = useState(moment().startOf("week").format("YYYY-MM-DD"))

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const {
          data: { sessions },
        } = await apiClient.get(`/api/v2/students/${id}/sessions/${weekStart}`)
        setScheduledSessions(sessions)
      } catch (error) {
        Alerter.error(`Failed to fetch student data\n${error}`)
      }
    }
    fetchStudentData()
  }, [id, weekStart])

  const handleScheduleChange = useCallback(
    action => {
      calendarEl.current.getApi()[action]()
      const selectedWeek = calendarEl.current.getApi().view.currentStart
      setWeekStart(moment(selectedWeek).format("YYYY-MM-DD"))
    },
    [calendarEl]
  )

  const events = useMemo(() => {
    return scheduledSessions.map(session => ({
      title: session.program_name,
      start: session.scheduled_at,
      end: moment(session.scheduled_at).add(session.duration, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
      category: session.status,
      className: moment(session.scheduled_at).isAfter(moment()) ? "future-lesson" : "past-lesson",
    }))
  }, [scheduledSessions])

  const currentWeek = moment(weekStart).format("MMMM Do")
  const currentWeekEnd = moment(weekStart).endOf("week").format("MMMM Do")

  return (
    <>
      <div className="student-dashboard-schedule-header">
        <div className="week-schedule-title">
          {STUDENTS_DASHBOARD_TEXTS.SCHEDULE_TITLE} |{" "}
          <span className="font-weight-bold">
            {currentWeek} - {currentWeekEnd}
          </span>
        </div>
        <div className="student-dashboard-schedule-controls flex">
          <button className="student-dashboard-schedule-control" onClick={() => handleScheduleChange("prev")}>
            <ArrowLeftIcon />
          </button>
          <button className="student-dashboard-schedule-control" onClick={() => handleScheduleChange("next")}>
            <ArrowRightIcon />
          </button>
        </div>
      </div>
      <Card className="student-dashboard-schedule-wrapper fc-weekly fc-schedule-session">
        <FullCalendar
          ref={calendarEl}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          defaultView="dayGridWeek"
          selectable={true}
          header={false}
          themeSystem="bootstrap5"
          allDaySlot={false}
          height="parent"
          displayEventTime={true}
          displayEventEnd={true}
          eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: true }}
          columnHeaderHtml={columnHeaderHtml}
          noEventsMessage={STUDENTS_DASHBOARD_TEXTS.NO_SESSIONS_SCHEDULED}
          events={events}
        />
      </Card>
    </>
  )
}
