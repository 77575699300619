import React from "react"
import { GaugeContainer, GaugeValueArc, GaugeReferenceArc, useGaugeState } from "@mui/x-charts/Gauge"

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState()

  if (valueAngle === null) {
    return null
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  }

  return (
    <g>
      <circle cx={cx} cy={cy} r={13} fill="#3d00f7" />
      <path d={`M ${cx} ${cy} L ${target.x} ${target.y}`} stroke="#3d00f7" strokeWidth={15} />
    </g>
  )
}

export default function GaugeComponent({ value }) {
  return (
    <GaugeContainer width={300} height={200} startAngle={-110} endAngle={110} value={value}>
      <GaugeReferenceArc />
      <GaugeValueArc />
      <GaugePointer />
    </GaugeContainer>
  )
}
