import React, { Fragment } from "react"
import { DashboardCard } from "../page"
import { Button } from "react-bootstrap"

const RESOURCES_LINKS = [
  {
    title: "Print Students Login Cards",
    link: "/school_admin/students",
  },
  {
    title: "Create School Admin",
    link: "school_admin/school_admins/new",
  },
]

export const ResourcesDashboard = () => {
  return (
    <Fragment>
      <DashboardCard title="Resources">
        <div className="mt-3 d-grid gap-1" style={{display: "grid"}}>
          {RESOURCES_LINKS.map(resource => (
            <Button key={resource.link} href={resource.link} variant="outline-primary">
              {resource.title}
            </Button>
          ))}
        </div>
      </DashboardCard>
    </Fragment>
  )
}