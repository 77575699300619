/* eslint-disable no-unused-vars */
import React from "react"
import ReactDOM from "react-dom"
export const EventContent = ({ event }) => {
  return (
    <div>
      <p className="text-center m-0 text-black">Scheduled Sessions</p>
    </div>
  )
}

const Event = ({ event, el }) => {
  ReactDOM.render(<EventContent event={event} />, el)
  return el
}

export const eventNewDiv = ({ event, el, view }) => {
  const eventDiv = document.createElement("div")
  const classes = Array.from(el.classList)
  eventDiv.classList.add(...classes)

  ReactDOM.render(<EventContent event={event} />, eventDiv)

  return eventDiv
}

export default Event
