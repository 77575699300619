import React from "react"
import { checkUrl, getScheduleOfflineAddress } from "../../helpers"
import { MeetingFormats } from "../../../../constants"

export const Location = ({ schedule }) => {
  let url

  switch (schedule.meeting_format) {
    case MeetingFormats.PencilSpaces: {
      if (schedule.pencil_space_link) {
        return (
          <a href={schedule.pencil_space_link} target="_blank" rel="noreferrer">
            Pencil spaces
          </a>
        )
      }

      return <span>Pencil spaces</span>
    }

    case MeetingFormats.Offline:
      return <span>{getScheduleOfflineAddress(schedule)}</span>

    case MeetingFormats.Online: {
      url = schedule.meeting_info ? checkUrl(schedule.meeting_info) : ""
      if (url) {
        return (
          <>
            <span className="mr-1">Online</span>
            <a href={schedule.meeting_info} target="_blank" rel="noreferrer">
              {schedule.meeting_info}
            </a>
          </>
        )
      }
      return <span>Online</span>
    }

    default:
      return <span>—</span>
  }
}
