import axios from "axios"
import Alerter from "./alerter"
import { values } from "lodash"
import ServerSideRendering from "./serverSideRendering"

const { document, window } = ServerSideRendering.wrapWindow()

const csrfToken = document.querySelector("[name=csrf-token]")?.content || ""

export const apiClient = axios.create({
  timeout: 10000,
  headers: {
    "Context-Type": "application/json",
    "X-CSRF-TOKEN": csrfToken,
  },
  validateStatus: status => {
    return status >= 200 && status < 400
  },
})

apiClient.interceptors.response.use(
  response => {
    if (response?.headers?.location) {
      window.location = new URL(response.headers.location).href
      return Promise.resolve(response)
    }
    return Promise.resolve(response)
  },
  error => {
    if (error?.response?.headers?.location) {
      window.location.replace(error.response.headers.location)
    } else {
      return Promise.reject(error)
    }
  }
)

export const request = async requestConfig => {
  return apiClient.request(requestConfig)
}

export const postAndRedirect = (url, redirectUrl, payload) => {
  apiClient
    .post(url, payload)
    .then(() => window.location.replace(redirectUrl))
    .catch(showErrorMessage)
}

export const post = (url, payload, onSuccess, onError, onFinally, options) => {
  apiClient
    .post(url, payload, options)
    .then(response => {
      if (onSuccess) onSuccess(response)
    })
    .catch(showErrorMessage)
    .finally(() => {
      if (onFinally) onFinally()
    })
}

export const putAndRedirect = (url, redirectUrl, payload) => {
  apiClient
    .put(url, payload)
    .then(() => window.location.replace(redirectUrl))
    .catch(showErrorMessage)
}

export const put = (url, payload, onSuccess, onFinally) => {
  apiClient
    .put(url, payload)
    .then(response => {
      onSuccess(response)
    })
    .catch(showErrorMessage)
    .finally(() => {
      onFinally()
    })
}

export const replaceId = (url, id) => url.replace(":id", id)

export const showErrorMessage = data => {
  Alerter.error(errorMessage(data.response))
}

const joinErrors = errors => {
  if (typeof errors === "string") {
    return errors
  } else {
    return values(errors).join("; ")
  }
}

const errorMessage = response => {
  if (!response) {
    return `Network error`
  } else if (response.status !== 422) {
    return `Server error`
  }

  return response.data.errors ? `Can't submit: ${joinErrors(response.data.errors)}` : `Can't submit`
}

export const showAllErrorsInAlert = ({ response }) => {
  if (!response) {
    Alerter.error("Network error")
  } else if (response.status !== 422) {
    Alerter.error("Server error")
  } else {
    Object.entries(response.data.errors)
      .map(([key, value]) => (key !== "base" ? `Can't submit: ${key} - ${joinErrors(value)}` : value))
      .forEach(message => Alerter.error(message))
  }
}

export const showBaseErrorsInAlert = ({ response }) => {
  if (!response) {
    Alerter.error("Network error")
  } else if (response.status !== 422) {
    Alerter.error("Server error")
  } else if (response.data.errors.base) {
    Object.values(response.data.errors.base).forEach(message => Alerter.error(message))
  } else {
    Alerter.error(`Can't submit`)
  }
}

export default apiClient
