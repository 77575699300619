import React, { useEffect, useState } from "react"
import cn from "classnames"

import LogoImage from "../../../../assets/images/logo.svg"
import LogoImageWhite from "../../../../assets/images/intro-logo-white.png"
import { ReactComponent as ChevronPrev } from "../../../../assets/images/icons/chevron-prev.svg"
import { ReactComponent as ChevronNext } from "../../../../assets/images/icons/chevron-next.svg"

const Subnav = ({ title, items }) => {
  return (
    <div className="introduction-header_subnav">
      <a className="introduction-header_link introduction-header_subnav-title">{title}</a>
      <div className="introduction-header_subnav-content">
        {items.map(item => (
          <a href={item.href} key={item.href} className="introduction-header_link introduction-header_subnav-link">
            {item.title}
          </a>
        ))}
      </div>
    </div>
  )
}

const Header = ({
  urls: { signUpUrl, signInUrl } = {},
  primary = true,
  parent = false,
  isSignUpPage = false,
  hideNav = false,
  headerActions = null,
}) => {
  const [currentMenuItem, setCurrentMenuItem] = useState(null)

  useEffect(() => require("../../../application/blocks/js-class-toggle").initializeToggle(), [])

  const menuItems = [
    {
      title: "How We Help",
      href: "https://braintrusttutors.com/how-we-help/",
    },
    {
      title: "Teach With Us",
      href: "https://braintrusttutors.com/teach-with-us/",
    },
    {
      title: "About",
      items: [
        { title: "Our Mission", href: "https://braintrusttutors.com/about-us/" },
        { title: "Press", href: "https://www.braintrusttutors.com/press/" },
        { title: "Blog", href: "https://www.braintrusttutors.com/blog/" },
      ],
    },
  ]

  if (hideNav) {
    return (
      <header className={cn("introduction-header", primary && "primary", parent && "parent")}>
        <div className="introduction-header_container">
          <a className="introduction-header_logo" href="https://braintrusttutors.com">
            <img src={primary ? LogoImage : LogoImageWhite} />
          </a>
          {headerActions}
        </div>
      </header>
    )
  }

  return (
    <header className={cn("introduction-header", primary && "primary", parent && "parent")}>
      <div className="introduction-header_container">
        <a className="introduction-header_logo" href="https://braintrusttutors.com">
          <img src={primary ? LogoImage : LogoImageWhite} />
        </a>

        <div className="introduction-header_nav">
          {menuItems.map(menuItem =>
            menuItem.items ? (
              <Subnav key={menuItem.title} {...menuItem} />
            ) : (
              <a key={menuItem.title} href={menuItem.href} className="introduction-header_link">
                {menuItem.title}
              </a>
            )
          )}
        </div>
        <div className="introduction-header_sign-links">
          <a className="introduction-header_sign-button" href={isSignUpPage ? signInUrl : signUpUrl}>
            {isSignUpPage ? "Sign In" : "Get Started"}
          </a>
        </div>
      </div>

      <div className="introduction-mobile-nav" id="introduction-mobile-nav">
        <button
          className="introduction-mobile-nav_opener js-class-toggle"
          type="button"
          data-target="#introduction-mobile-nav"
          data-class="-show"
        />

        <div className="introduction-mobile-nav_menu-wrapper">
          <button
            className="introduction-mobile-nav_closer js-class-toggle"
            type="button"
            data-target="#introduction-mobile-nav"
            data-class="-show"
          />

          <div className="introduction-mobile-nav_container">
            <div
              className="introduction-mobile-nav_body"
              style={{ transform: `translateX(${currentMenuItem ? "-50%" : 0})` }}
            >
              <div className="introduction-mobile-nav_main-menu">
                <div className="introduction-mobile-nav_menu-items">
                  {menuItems.map(menuItem => (
                    <a
                      key={menuItem.title}
                      href={menuItem.href}
                      className="introduction-mobile-nav_link"
                      onClick={() => (menuItem.href ? null : setCurrentMenuItem(menuItem))}
                    >
                      {menuItem.title} {!!menuItem.items && <ChevronNext />}
                    </a>
                  ))}
                </div>
                <div className="introduction-mobile-nav_sign-link">
                  {isSignUpPage ? (
                    <a className="introduction-mobile-nav_sign-button" href={signInUrl}>
                      Sign In
                    </a>
                  ) : (
                    <a className="introduction-mobile-nav_sign-button" href={signUpUrl}>
                      Get Started
                    </a>
                  )}
                </div>
              </div>
              <div className="introduction-mobile-nav_sub-menu">
                <div className="introduction-mobile-nav_menu-items">
                  <a className="introduction-mobile-nav_link -back" onClick={() => setCurrentMenuItem(null)}>
                    <ChevronPrev /> Back
                  </a>
                  {!!currentMenuItem &&
                    currentMenuItem.items.map(item => (
                      <a key={item.title} href={item.href} className="introduction-mobile-nav_link">
                        {item.title}
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
