import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { Roles } from "../../../../constants"
import { DataContext } from "../../../../contexts"
import { getStatus } from "../../helpers"
import { Settings } from "./settings"
import { Students } from "./students"
import { Tutors } from "./tutors"

const ReportStatus = () => {
  const { report } = useContext(DataContext)
  const { report_date } = useParams()

  if (!report || report.empty || !report_date) return null

  const { Icon, classname, header, text } = getStatus(report.status)

  return (
    <div className="card mb-4 px-4 py-3">
      <div className={`session-status ${classname}`}>
        {Icon && (
          <div className="session-status-icon">
            <Icon />
          </div>
        )}
        <span className="session-status-text font-weight-semibold">{header}</span>
      </div>
      <div className="mt-3 text-center font-size-small text-gray-chat px-5">{text(report)}</div>
    </div>
  )
}

const GroupInformation = () => {
  const { schedule, role } = useContext(DataContext)

  if (!schedule) return null

  return (
    <div className="card px-4 py-4 session-information">
      <Settings schedule={schedule} />
      {role !== Roles.Parent && (
        <>
          <hr className="divider fullwidth soft-color" />
          <Students schedule={schedule} />
          <hr className="divider fullwidth soft-color" />
          <Tutors schedule={schedule} />
        </>
      )}
    </div>
  )
}

const Index = () => (
  <>
    <ReportStatus />
    <GroupInformation />
  </>
)

export default Index
