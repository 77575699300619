import React from "react"
import { NavLink, BrowserRouter as Router } from "react-router-dom"
import { NAV_CONFIG } from "../../common/constants"

const NavbarLinks = ({ userRole, hasNewMessage, hasSessionRequests, hasInterviewRequests }) => {
  const navLinks = NAV_CONFIG[userRole] || []
  const newEvents = { hasNewMessage, hasSessionRequests, hasInterviewRequests }

  return (
    <Router className="navbar-links">
      <div className="main-nav">
        {navLinks.map(({ path, label, hasNew }) => (
          <React.Fragment key={path}>
            <NavLink to={path} reloadDocument relative="path" className="main-nav_item">
              {label}
            </NavLink>
            {hasNew && newEvents[hasNew] && <span>New!</span>}
          </React.Fragment>
        ))}
      </div>
    </Router>
  )
}

export default NavbarLinks
