import React, { Fragment, useEffect, useState } from "react"
import { DashboardCard } from "../page"
import GaugeComponent from "../../../gauge"
import apiClient from "../../../../common/apiClient"
import moment from "moment"
import { Spinner } from "react-bootstrap"

const InstructionalDaysDetails = ({ startDate, endDate, valueOfGauge, daysRemaining }) => {
  return (
    <Fragment>
      <p className="text-center m-0">
        {startDate} to {endDate}
      </p>
      <GaugeComponent value={valueOfGauge} />

      <div className="font-size-medium font-weight-semibold text-center">
        {daysRemaining === 0 ? "No" : daysRemaining} {daysRemaining > 0 && "or more"} days of instruction
      </div>
    </Fragment>
  )
}

export const InstructionalDaysDashboard = () => {
  const [data, setData] = useState()

  useEffect(() => {
    const instructionalDaysResponse = async () => {
      const response = await apiClient("/api/v2/school_admins/instructional_days")
      setData(response && response.data)
    }
    instructionalDaysResponse()
  }, [])

  const dates = data && data.dates
  const daysRemaining = data && data.days_remaining
  const daysCompleted = data && data.days_completed
  const totalDays = data && data.total_days
  const valueOfGauge = totalDays === 0 ? 0 : (daysCompleted / totalDays) * 100
  const startDate = dates && moment(dates.split(" to ")[0], "MM/DD/YYYY").format("MMMM D, YYYY")
  const endDate = dates && moment(dates.split(" to ")[1], "MM/DD/YYYY").format("MMMM D, YYYY")

  return (
    <Fragment>
      <DashboardCard title="Instructional Days">
        {!dates ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              width: "100%",
              height: "240px",
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <InstructionalDaysDetails
            daysRemaining={daysRemaining}
            endDate={endDate}
            startDate={startDate}
            valueOfGauge={valueOfGauge}
          />
        )}
      </DashboardCard>
    </Fragment>
  )
}
