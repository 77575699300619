import { ClockIcon, Location, PersonIcon } from "@/assets/images"
import moment from "moment"
import React, { useState } from "react"
import { Badge, Button, Container } from "react-bootstrap"
import { STUDENTS_DASHBOARD_TEXTS } from "../contants"
const TutorImage = ({ src, alt }) => {
  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  if (error) {
    return (
      <figure>
        <PersonIcon className="next-lesson-tutor-no-photo" />
      </figure>
    )
  }

  return (
    <figure>
      <img src={src} onError={handleError} alt={alt} />
    </figure>
  )
}
export const NextSession = ({ nextSession, canStartNextSession, handleStartSession }) => {
  return (
    <Container className="next-lesson-container">
      {nextSession.tutor_name ? (
        <div className="next-lesson-tutor-data">
          <div className="next-lesson-tutor-photo">
            <TutorImage src={nextSession.tutor_photo} alt={`Tutor ${nextSession.tutor_name}`} />
          </div>
          <div className="next-lesson-tutor-details">
            <div className="next-lesson-tutor-title">
              <PersonIcon className="next-lesson-tutor-icon" />
              <div className="next-lesson-tutor-name">{nextSession.tutor_name.split(", ")[1]}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="next-lesson-tutor-data">
          <div className="next-lesson-tutor-photo"></div>
          <div className="next-lesson-tutor-details">
            <div className="next-lesson-tutor-title">
              <div className="next-lesson-tutor-not-assigned">{STUDENTS_DASHBOARD_TEXTS.TUTOR_NOT_ASSIGNED}</div>
            </div>
          </div>
        </div>
      )}

      <div className="next-lesson-program-wrapper">
        <div className="next-lesson-program-info">
          <span className="next-lesson-program-name">{nextSession.program_name}</span>
          <div className="next-lesson-time-duration">
            <ClockIcon className="next-lesson-program-time-icon" />
            <div className="next-lesson-duration-text">{nextSession.duration}</div>
          </div>
          {nextSession.in_progress && (
            <Badge pill className="next-lesson-status">
              In Progress
            </Badge>
          )}
        </div>
        <div className="next-lesson-formatted-wrapper">
          {!nextSession.in_progress && (
            <div className="next-lesson-formatted-text-intro">{STUDENTS_DASHBOARD_TEXTS.FORMATTED_TEXT_INTRO}</div>
          )}
          <div className="next-lesson-formatted-text">{nextSession.formatted_scheduled_at_text}</div>
        </div>

        {nextSession.location === "offline" ? (
          <div className="next-lesson-address-area">
            <Location className="next-lesson-address-icon" />
            <p className="next-lesson-address-text">{nextSession.address}</p>
          </div>
        ) : (
          <Button
            className={"btn btn-primary next-lesson-data-button"}
            size="sm"
            onClick={canStartNextSession ? handleStartSession : undefined}
            disabled={!canStartNextSession}
          >
            {canStartNextSession ? "Start" : `Can be started in ${moment(nextSession.scheduled_at).toNow(true)}`}
          </Button>
        )}
      </div>
    </Container>
  )
}
