import React, { useContext, useCallback } from "react"
import { DataContext } from "../../../contexts"
import { TutorEditDialog } from "./tutor_edit_dialog"
import { TutorsList } from "./tutors_list"
import { Roles } from "../../../constants"
import { ReactComponent as EmptyListIcon } from "../../../assets/images/empty_list_icon.svg"
import { useDialogState } from "../../../hooks/useDialogState"
import ConfirmationModalProvider from "../../blocks/ConfirmationModal"

export const Tutors = () => {
  const { schedule } = useContext(DataContext)
  const { dialogState, openDialog, closeDialog } = useDialogState()
  const { role } = useContext(DataContext)
  const isAdmin = role === Roles.Admin

  const openEditDialog = useCallback(
    connection => {
      openDialog(connection ? [connection] : [])
    },
    [openDialog]
  )

  return (
    <>
      {schedule.connections && schedule.connections.length > 0 ? (
        <TutorsList
          title="Active tutors"
          connections={schedule.connections}
          onEdit={isAdmin ? openEditDialog : undefined}
          withAddButton={isAdmin}
        />
      ) : (
        <div className="card align-items-center justify-content-center flex-grow-1 min-h-400-px">
          <EmptyListIcon />

          <h6 className="text-gray-chat font-weight-medium my-4">No tutors added yet</h6>

          {isAdmin ? (
            <button className="btn btn-primary" onClick={() => openEditDialog()}>
              Add tutor
            </button>
          ) : null}
        </div>
      )}

      <ConfirmationModalProvider>
        <TutorEditDialog connections={dialogState.data} open={dialogState.open} onClose={closeDialog} />
      </ConfirmationModalProvider>
    </>
  )
}
