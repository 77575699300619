import React, { useContext, useState, useMemo, useEffect } from "react"
import { Modal, Table, Card, Row, Col, Dropdown } from "react-bootstrap"
import { OpeningContext } from "../context"
import { useUpdateTutorStatus, useSaveOpeningSchedule, useGetTutorApplications } from "../hooks"
import { Schedule } from "../../../common/edit/schedule"
import { Timetable } from "../../../common/timetable"
import { formatStartOfDayKeepZone } from "../../../../../helpers/dates"

const Actions = Object.freeze({
  RECOMMEND: "recommend",
  APPROVE: "approve",
  REJECT: "reject",
})

const TutorAppStatuses = Object.freeze({
  APPLIED: "applied",
  SCHEDULE_UP: "schedule_up",
  RECOMMENDED: "recommended",
  APPROVED: "approved",
  REJECTED: "rejected",
})

const OpeningStatus = Object.freeze({
  ACTIVE: "active",
  CLOSED: "closed",
})

const checkApprovalStatus = approvalStatus => (...list) => list.some(el => el === approvalStatus)

const Action = ({ children, className: cn = "", ...params }) => {
  const className = `btn btn-block ${cn}`.trim()
  return (
    <button className={className} {...params}>
      {children}
    </button>
  )
}

const ModalContent = ({ children, onClose, action, actionsRowClassName }) => {
  return (
    <Col>
      <Row className="mt-2 mb-4">{children}</Row>
      <Row className={actionsRowClassName}>
        <Col sm>
          <Action className="btn-white" onClick={() => onClose()}>
            Back
          </Action>
        </Col>
        <Col sm>{action}</Col>
      </Row>
    </Col>
  )
}

const Confirmation = ({ data, onClose }) => {
  const { action: status, tutorApplication, group } = data
  const { loading, request } = useUpdateTutorStatus({
    onSuccess: onClose,
  })

  const {
    id: tutor_application_id,
    tutor: { name: tutorName },
  } = tutorApplication
  let text = "Unknown action"
  let actionButton = null
  const dataToSent = { status, tutor_application_id }
  const buildActionButton = ({ title, requestData = dataToSent, className }) => (
    <Action className={`btn-outline-primary ${className}`.trim()} onClick={() => request(requestData)}>
      {title}
    </Action>
  )
  switch (status) {
    case Actions.RECOMMEND: {
      text = `Are you sure you want to suggest ${tutorName} for this opportunity?`
      actionButton = buildActionButton({ title: "Send to admin" })
      break
    }
    case Actions.APPROVE: {
      const requestData = { ...dataToSent, opening_group_id: group.id }
      text = `Are you sure you want to approve ${tutorName} as a tutor for ${group.name}? This will create a session with them`
      actionButton = buildActionButton({ title: "Approve", requestData })
      break
    }
    case Actions.REJECT: {
      text = `Are you sure you want to reject ${tutorName}?`
      actionButton = buildActionButton({ title: "Reject", className: "-error" })
      break
    }
  }
  if (loading) {
    // eslint-disable-next-line react/display-name
    actionButton = (
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <ModalContent onClose={onClose} action={actionButton}>
      <Col className="font-size-big">{text}</Col>
    </ModalContent>
  )
}

const ConfirmModal = ({ open, onClose, data }) => {
  return (
    <Modal animation={false} scrollable={true} show={open} onHide={() => {}}>
      <Modal.Body>
        <Confirmation data={data} onClose={onClose} />
      </Modal.Body>
    </Modal>
  )
}

const ScheduleForm = ({ onClose, application }) => {
  const { options } = useContext(OpeningContext)
  // todo - request hook, save and close buttons
  const [openingState, setOpeningState] = useState({
    recurrence: application.recurrence,
    started_at: formatStartOfDayKeepZone(application.started_at),
    ended_at: formatStartOfDayKeepZone(application.ended_at),
    duration: application.duration,
    schedule: application.schedule,
    schedule_flexible: false,
    schedule_preference: "",
  })
  const updateOpeningState = data => {
    setOpeningState({ ...openingState, ...data })
  }
  const { loading, request } = useSaveOpeningSchedule({
    id: application.id,
    onSuccess: () => setTimeout(() => onClose({ ...application, ...openingState }), 0),
  })
  return (
    <ModalContent
      onClose={onClose}
      action={
        loading ? (
          <div className="spinner-wrapper">
            <div className="spinner-border text-primary" />
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => request(openingState)}>
            Update
          </button>
        )
      }
      actionsRowClassName="max-w-400-px"
    >
      <Row className="px-4">
        <Col lg={24}>
          <h4 className="mb-4">Configure schedule for a tutor in opportunity</h4>
          <p className="mb-0">{application.schedule_preference}</p>
        </Col>
      </Row>
      <Schedule opening={openingState} setOpening={updateOpeningState} options={options} />
    </ModalContent>
  )
}

const OpeningScheduleModal = ({ open, onClose, application }) => {
  return (
    <Modal animation={false} scrollable={true} show={open} size="lg" onHide={() => {}}>
      <Modal.Body>
        <ScheduleForm onClose={onClose} application={application} />
      </Modal.Body>
    </Modal>
  )
}

const OpenModalButtonAction = ({ children, className, ...props }) => (
  <button className={`btn btn-link ${className}`.trim()} {...props}>
    {children}
  </button>
)

const DropdownApproveButton = ({ open, tutorApplication, groupList }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="" className="btn btn-link mr-2">
        Approve
      </Dropdown.Toggle>
      <Dropdown.Menu className="-fullwidth">
        {groupList.map(group => (
          <Dropdown.Item
            key={group.id}
            onClick={() => {
              open({ action: Actions.APPROVE, tutorApplication, group })
            }}
          >
            {group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const OpenModalButton = ({
  openActionModal,
  openScheduleModal,
  tutorApplication,
  group,
  groupList,
  unappliedCount,
}) => {
  const { permissions, opening } = useContext(OpeningContext)
  const { approval_status: approvalStatus } = tutorApplication
  if (group || opening.status === OpeningStatus.CLOSED || unappliedCount === 0) return null
  const checkStatus = checkApprovalStatus(approvalStatus)
  const components = []
  if (permissions.can_manage_openings) {
    components.push(
      <Row key="can_manage_openings" className="col flex-column align-items-end">
        {/* schedule_up, rejected */}
        {!permissions.can_approve_tutor_applications &&
          checkStatus(TutorAppStatuses.SCHEDULE_UP, TutorAppStatuses.REJECTED) && (
            <OpenModalButtonAction onClick={() => openActionModal({ action: Actions.RECOMMEND, tutorApplication })}>
              Send to admin
            </OpenModalButtonAction>
          )}
        {/* flexible + applied, schedule_up */}
        {opening.schedule_flexible && checkStatus(TutorAppStatuses.APPLIED, TutorAppStatuses.SCHEDULE_UP) && (
          <OpenModalButtonAction onClick={() => openScheduleModal(tutorApplication)}>
            {approvalStatus === TutorAppStatuses.SCHEDULE_UP && "Edit schedule"}
            {approvalStatus === TutorAppStatuses.APPLIED && "Set schedule"}
          </OpenModalButtonAction>
        )}
      </Row>
    )
  }
  if (permissions.can_approve_tutor_applications) {
    components.push(
      // recommended, schedule_up
      <Row key="can_approve_tutor_applications" className="col flex-column align-items-end">
        {checkStatus(TutorAppStatuses.RECOMMENDED, TutorAppStatuses.SCHEDULE_UP) ? (
          unappliedCount > 1 ? (
            <DropdownApproveButton open={openActionModal} tutorApplication={tutorApplication} groupList={groupList} />
          ) : (
            <OpenModalButtonAction
              onClick={() => openActionModal({ action: Actions.APPROVE, tutorApplication, group: groupList[0] })}
            >
              Approve
            </OpenModalButtonAction>
          )
        ) : null}
        {/* schedule_up, recommended */}
        {checkStatus(TutorAppStatuses.SCHEDULE_UP, TutorAppStatuses.RECOMMENDED) && (
          <OpenModalButtonAction onClick={() => openActionModal({ action: Actions.REJECT, tutorApplication })}>
            Reject
          </OpenModalButtonAction>
        )}
      </Row>
    )
  }
  return components
}

const TutorName = ({ name, tutorId }) => {
  const { urls } = useContext(OpeningContext)
  const { tutor_url: url } = urls
  const href = `${url}/${tutorId}/profile`
  return <a href={href}>{name}</a>
}

const Status = ({ status, statusText, group }) => {
  if (group)
    return (
      <span className="opening_tutor_status -approved">
        {statusText}, {group.name}
      </span>
    )
  return <span className={`opening_tutor_status ${status === "Rejected" ? "-rejected" : ""}`.trim()}>{statusText}</span>
}

const List = ({ list, openActionModal, groups, openScheduleModal, scheduleFlexible }) => {
  const appliedGroups = useMemo(() => list.filter(({ group }) => !!group), [list])

  const selectableGroups = useMemo(() => {
    return groups.filter(({ id }) => !appliedGroups.some(({ group }) => id === group.id))
  }, [appliedGroups, groups])
  return (
    <Card className="mb-3 flat sm col-24">
      <Card.Header>
        <b>Tutors applied</b>
      </Card.Header>
      <Card.Body>
        <Table className="grid-table" size="sm">
          <thead>
            <tr>
              <th width="17%">Name</th>
              <th width="17%">Contacts</th>
              <th width="22%">Note</th>
              {scheduleFlexible && <th width="22%">Schedule</th>}
              <th width="10%">Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map((application, idx) => {
              const { tutor, status, group, note, status_text, approval_status } = application
              return (
                <tr key={`tutor-${tutor.id}-${idx}`}>
                  <td>
                    <TutorName name={tutor.name} tutorId={tutor.id} />
                    {tutor.degree ? <p>{tutor.degree}</p> : null}
                  </td>
                  <td>{tutor.email ? tutor.email : "-"}</td>
                  <td>{note ? note : "-"}</td>
                  {scheduleFlexible && (
                    <td>
                      {approval_status === TutorAppStatuses.APPLIED ? (
                        "–"
                      ) : (
                        <Timetable opening={{ ...application, schedule_flexible: false }} />
                      )}
                    </td>
                  )}
                  <td>
                    <Status group={group} status={status} statusText={status_text} />
                  </td>
                  <td>
                    <OpenModalButton
                      openActionModal={openActionModal}
                      openScheduleModal={openScheduleModal}
                      tutorApplication={application}
                      groupList={selectableGroups}
                      group={group}
                      unappliedCount={groups.length - appliedGroups.length}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

const AppliedTutorList = () => {
  const { opening, openingId } = useContext(OpeningContext)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [confirmationData, setConfirmationData] = useState({})
  const { data: tutorList, request } = useGetTutorApplications({ openingId })

  useEffect(() => {
    request()
  }, [request])

  const closeModal = async () => {
    setOpenConfirmation(false)
    await request()
  }
  const openConfirmationModal = data => {
    setConfirmationData(data)
    setOpenConfirmation(true)
  }

  const [application, setApplication] = useState(null)

  const openScheduleModal = app => setApplication(app)
  const closeScheduleModal = async () => {
    setApplication(null)
    await request()
  }
  return (
    <>
      <List
        list={tutorList}
        openActionModal={openConfirmationModal}
        groups={opening.openingGroups}
        openScheduleModal={openScheduleModal}
        scheduleFlexible={opening.schedule_flexible}
      />
      <ConfirmModal open={openConfirmation} data={confirmationData} onClose={closeModal} />
      {opening.schedule_flexible && (
        <OpeningScheduleModal open={application !== null} application={application} onClose={closeScheduleModal} />
      )}
    </>
  )
}

export default AppliedTutorList
