import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import Alerter from "../../../common/alerter"
import apiClient from "../../../common/apiClient"
import { className } from "../../../helpers/className"
import useViewport from "../../../hooks/useViewport"
import Navbar from "../../Navbar"
import { STUDENTS_DASHBOARD_TEXTS } from "./contants"
import { NextSession } from "./hero_section/NextSession"
import { NoSession } from "./hero_section/NoSession"
import { StudentSchedule } from "./Schedule"

export const StudentDashboardPage = ({ student_id, sign_out_button }) => {
  const { isMobileViewport } = useViewport()
  const [studentData, setStudentData] = useState(null)

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const { data } = await apiClient.get(`/api/v2/students/${student_id}`)
        setStudentData(data)
      } catch (error) {
        Alerter.error(`Failed to fetch student data \n${error}`)
      }
    }

    fetchStudentData()
  }, [student_id])

  if (!studentData) return null

  const nextSession = studentData?.next_session
  const doesNextSessionExist = Object.keys(nextSession).length > 0
  const canStartNextSession = moment(nextSession?.scheduled_at).isBefore(moment().add(5, "minutes"))
  const cardClass = className(isMobileViewport ? "px-3 py-4" : "", doesNextSessionExist ? "next-lesson" : "no-lesson")

  const handleStartSession = () => {
    Alerter.info("Starting session...")
    window.open(nextSession.address, "_blank")
  }

  return (
    <>
      <header className="header">
        <Navbar user={studentData} sign_out_button={sign_out_button} />
      </header>
      <Container fluid className="p-0 d-flex flex-column flex-grow-1">
        <Row>
          <Col className="p-0">
            <h2 className="student-dashboard-title mb-4 pl-2">
              {STUDENTS_DASHBOARD_TEXTS.GREETINGS_TEXT} <span>{studentData.name.split(", ")[1]}!</span>
            </h2>
            <Container className="student-dashboard-content">
              <Row>
                <Col as="main" xs={24}>
                  <Card className={cardClass}>
                    {doesNextSessionExist ? (
                      <NextSession
                        nextSession={nextSession}
                        canStartNextSession={canStartNextSession}
                        handleStartSession={handleStartSession}
                      />
                    ) : (
                      <NoSession />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row className="px-2">
                <StudentSchedule id={student_id} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}
