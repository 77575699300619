import LogoImageWhite from "@assets/images/logo-braintrust-white.png"
import StudentImage from "@assets/images/sign-girl-student.png"
import React from "react"
import { LoginForm } from "./login-form"

export const StudentLoginPage = ({ googleOAuthUrl }) => (
  <div className="student-login-wrapper">
    <div className="student-login-nav">
      <a className="introduction-header_logo" href="https://braintrusttutors.com">
        <img src={LogoImageWhite} alt="Braintrust Logo" />
      </a>
      <a href="#">I need help</a>
    </div>
    <div className="student-login-content">
      <div className="student-login-content-left">
        <img src={StudentImage} alt="Student Login" className="student-login-image" />
      </div>
      <div className="student-login-content-right">
        <LoginForm googleOAuthUrl={googleOAuthUrl} />
      </div>
    </div>
  </div>
)
