import React from "react"
import { Spinner } from "react-bootstrap"

const Loading = ({ style }) => {
  return (
    <Spinner
      animation="border"
      className="mr-4"
      style={style}
    />
  )
}

export default Loading
