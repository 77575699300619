import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import { Group } from "../group"

const TutorInfo = ({ tutorName }) => {
  return (
    <Row>
      <b className="opening_tutor_status -approved mr-2">Tutor: {tutorName}</b>
      <b className="opening_session">Session created</b>
    </Row>
  )
}

const Individual = ({ student, tutorList, group }) => {
  const tutor = useMemo(() => tutorList.find(({ group: g }) => g && group && g.id === group.id)?.tutor, [
    group,
    tutorList,
  ])
  return (
    <>
      <span className="h5">{student.name}</span>
      {tutor && <TutorInfo tutorName={tutor.name} />}
    </>
  )
}

export const School = ({ opening, tutorList = [], showEdit = false }) => {
  const { school, student, group, openingGroups } = opening

  return (
    <Col>
      <Row className="mb-4">
        <Col>
          <Row className="align-items-center">
            <Col xs={24} sm={19} xl={21}>
              <span className="h3">{school.name}</span>
            </Col>
            {showEdit && (
              <Col xs={24} sm={5} xl={3}>
                <a className="btn btn-outline-primary fullwidth" href={`${window.location.href}/edit`}>
                  Edit
                </a>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          {student ? (
            <Individual group={group} tutorList={tutorList} student={student} />
          ) : (
            openingGroups.map((group, idx) => <Group key={idx} group={group} tutorList={tutorList} />)
          )}
        </Col>
      </Row>
    </Col>
  )
}
