import React, { useEffect, useState, useCallback, useMemo } from "react"
import { Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import Dialog from "../../blocks/Dialog"
import apiClient from "../../../common/apiClient"
import Alerter from "../../../common/alerter"
import Loading from "../../../common/Loading"

const AuthenticationForm = ({ setPassword, password }) => {
  return (
    <Row>
      <Col xs={24} md={12} lg={8}>
        <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Password</span>
        <Form.Control
          type="password"
          className="v-2 mb-4"
          value={password}
          onChange={e => {
            setPassword(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

const AuthenticationModal = ({ endpoint, setOpen }) => {
  const [password, setPassword] = useState("")

  const onSubmit = async e => {
    e.preventDefault()

    return apiClient
      .get(endpoint, { params: { password: password } })
      .then(() => {
        setOpen(false)
      })
      .catch(error => {
        Alerter.error(error.response.data.error)
      })
  }

  return (
    <Dialog
      open={open}
      className="edit_tutor_dialog"
      keyboard={false}
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      }}
      showCloseButton={false}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthenticationForm password={password} setPassword={setPassword} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onSubmit} disabled={!password}>
          Submit
        </button>
      </Modal.Footer>
    </Dialog>
  )
}

export const SchoolAdminStudentsPage = ({ studentsAPIEndpoint, authenticationEndpoint, canViewStudentList }) => {
  const [state, setState] = useState({
    students: [],
    totalCount: 0,
    perPage: 5,
    page: 0,
    loading: true,
    gridLoading: true,
  })
  const [open, setOpen] = useState(!canViewStudentList)
  const { students, totalCount, perPage, page } = state

  const fetchStudents = useCallback(async () => {
    // Add an offset of 1 to the current page number, and subtract 1
    // from the server's response to compensate for DataGrid's page count
    // being a 0-based index.
    return apiClient
      .get(studentsAPIEndpoint, { params: { per_page: perPage, page: page + 1 } })
      .then(({ data: { students, per_page, total_count, page } }) => {
        setState({
          students: students,
          totalCount: Number(total_count),
          perPage: Number(per_page),
          page: Number(page) - 1,
          gridLoading: false,
          loading: false,
        })
      })
      .catch(() => {
        setState(prevState => ({ ...prevState, loading: false, gridLoading: false }))
        Alerter.error(`Could not fetch Students.`)
      })
  }, [studentsAPIEndpoint, perPage, page])

  useEffect(() => {
    fetchStudents()
  }, [fetchStudents])

  const rows = useMemo(() => {
    return students.map((student, index) => {
      return {
        id: index,
        identifier: student.id,
        name: `${student.first_name} ${student.last_name}`,
        email: student.email,
      }
    })
  }, [students])

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "name",
        headerClassName: "table-header",
        resizable: false,
        flex: 1,
        renderCell: params => <a href={`/school_admin/students/${params.row.identifier}`}>{params.row.name}</a>,
      },
      { field: "email", headerName: "email", headerClassName: "table-header", resizable: false, flex: 1 },
    ],
    []
  )

  return (
    <Container fluid className="p-0 d-flex flex-column flex-grow-1">
      {!open ? (
        <Row>
          <Col className="p-0">
            <h2 className="users-navbar_title mb-4 pl-2">Students</h2>
            {state.loading ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: "40vh",
                }}
              >
                <Loading style={{ width: "100px", height: "100px" }} />
              </div>
            ) : (
              <Container className="school-admins-dashboard-content">
                <Row>
                  <Col as="main" xs={24}>
                    <Card className="px-3 py-4">
                      <div style={{ height: "100%", width: "100%" }}>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          autosizeOptions={{
                            includeHeaders: true,
                            includeOutliers: true,
                            outliersFactor: 1.5,
                            expand: true,
                          }}
                          // onFilterModelChange={fetchStudents}
                          // filterMode="server"

                          paginationModel={{ pageSize: perPage, page: page }}
                          onPaginationModelChange={({ page, pageSize }) => {
                            setState(prevState => ({ ...prevState, page, perPage: pageSize, gridLoading: true }))
                          }}
                          pageSizeOptions={[5, 10, 25]}
                          pagination
                          paginationMode="server"
                          rowCount={totalCount}
                          autoHeight
                          loading={state.gridLoading}
                          className="bg-white"
                          density="compact"
                          sx={{
                            border: "none",
                            fontFamily: "'Poppins', sans-serif",

                            "& .MuiDataGrid-columnHeaders": {
                              backgroundColor: "#f5f5f5",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                              fontWeight: "600",
                            },
                            "& .MuiTablePagination-displayedRows": {
                              padding: 0,
                              margin: 0,
                            },
                            "& .MuiButton-root": {
                              color: "#3c00f5",
                            },
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            pagination: {
                              component: "div",
                            },
                            tablePagination: {
                              component: "div",
                            },
                          }}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
      ) : (
        <AuthenticationModal endpoint={authenticationEndpoint} setOpen={setOpen} />
      )}
    </Container>
  )
}
