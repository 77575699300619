export const Entities = Object.freeze({
  Schedules: "schedules",
  Reports: "reports",
  Weeks: "weeks",
  StudentReports: "student_reports",
  GroupSessions: "group_sessions",
  GroupSessionStudents: "group_session_students",
  ProgramProgresses: "program_progresses",
  Students: "students",
  Tutors: "tutors",
  Tutor: "tutor",
  Autocomplete: "autocomplete",
  Schools: "schools",
  Educatables: "educatables",
  Programs: "programs",
  Connections: "connections",
  User: "user",
  Dashboard: "dashboard",
  Sessions: "sessions",
  Openings: "openings",
  Applications: "applications",
})

export const Methods = Object.freeze({
  Get: "GET",
  Put: "PUT",
  Post: "POST",
  Delete: "DELETE",
  Patch: "PATCH",
})

export const Roles = Object.freeze({
  Admin: "admin",
  Parent: "parent",
  Tutor: "tutor",
})

export const SearchParamsKeys = Object.freeze({
  Page: "page",
  Sort: "sort",
  RowsPerPage: "rowsPerPage",
})

export const Sort = Object.freeze({
  ASC: "ASC",
  DESK: "DESK",
})

export const MeetingFormats = Object.freeze({
  Online: "online",
  PencilSpaces: "pencil_spaces",
  Offline: "offline",
})

export const Grades = Object.freeze({
  kindergarten: {
    key: "kindergarten",
    name: "Kindergarten",
  },
  firstGrade: {
    key: "first_grade",
    name: "1st Grade",
  },
  secondGrade: {
    key: "second_grade",
    name: "2nd Grade",
  },
  thirdGrade: {
    key: "third_grade",
    name: "3rd Grade",
  },
  fourthGrade: {
    key: "fourth_grade",
    name: "4th Grade",
  },
  fifthGrade: {
    key: "fiveth_grade",
    name: "5th Grade",
  },
  sixthGrade: {
    key: "sixth_grade",
    name: "6th Grade",
  },
  seventhGrade: {
    key: "seventh_grade",
    name: "7th Grade",
  },
  eighthGrade: {
    key: "eightth_grade",
    name: "8th Grade",
  },
  ninthGrade: {
    key: "nineth_grade",
    name: "9th Grade",
  },
  tenthGrade: {
    key: "tenth_grade",
    name: "10th Grade",
  },
  eleventhGrade: {
    key: "eleventh_grade",
    name: "11th Grade",
  },
  twelfthGrade: {
    key: "twelveth_grade",
    name: "12th Grade",
  },
})

export const LearningLevel = [
  {
    key: "preschool",
    name: "Preschool",
  },
  {
    key: "elementary",
    name: "Elementary",
  },
  {
    key: "middle_school",
    name: "Middle School",
  },
  {
    key: "high_school",
    name: "High School",
  },
  {
    key: "other",
    name: "Other",
  },
]
